import React from 'react';
import PropTypes from 'prop-types';
import useRest from 'q3-ui-rest';
import { useTranslation } from 'q3-ui-locale';
import Box from '@material-ui/core/Box';
import useDecodedSearch from '../useDecodedSearch';
import PublicPageContent from '../PublicPageContent';

const CoursesRegistrationView = ({ children, text }) => {
  const { course } = useDecodedSearch();
  const { t } = useTranslation('descriptions');

  const r = useRest({
    key: 'course',
    runOnInit: true,
    url: `/courses-public/${course}`,
  });

  const render = () => {
    if (r.fetching) return t('hangTight');

    if (r.fetchingError)
      return (
        <Box maxWidth="100%" width={550}>
          {t('errorAcceptingCourseInvitation')}
        </Box>
      );

    return (
      <>
        <PublicPageContent text={text} vars={r.course} />
        {children(r.course)}
      </>
    );
  };

  return render();
};

CoursesRegistrationView.propTypes = {
  children: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default CoursesRegistrationView;
