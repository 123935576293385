import React from 'react';
import CoursesJoin from '../CoursesJoin';
import CoursesRegistrationView from '../CoursesRegistrationView';
import withRegistration from '../withRegistration';

/**
 * @NOTE
 * Refactored during paid coach offerings.
 * Largely, the exact same workflow as <RegistrationOpen /> now,
 * But with a different title. Could likely refactor further if
 * The two components remain identical in future iterations.
 */

const RegistrationAccept = withRegistration(
  ({ id, email }) => (
    <CoursesJoin courseId={id} email={email} />
  ),
  CoursesRegistrationView,
  { text: 'acceptInvitation' },
);

export default RegistrationAccept;
