import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import axios from 'axios';

import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'q3-ui-locale';

const CoursesJoin = ({ courseId, email, redirectUrl }) => {
  const [error, setError] = React.useState(false);
  const { t } = useTranslation('descriptions');

  React.useEffect(() => {
    axios
      .post('/join', {
        course: courseId,
        email,
      })
      .then(() => {
        navigate(redirectUrl);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  return error ? (
    <Alert severity="error">
      {t('couldNotJoinCourse')}
    </Alert>
  ) : null;
};

CoursesJoin.propTypes = {
  courseId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
};

CoursesJoin.defaultProps = {
  redirectUrl: '/open-registration-done',
};

export default CoursesJoin;
